import React, { MouseEvent, useState } from 'react';
import { Layout, Menu, Dropdown, message, Button } from 'antd';
import { useAsyncEffect, useRequest } from 'ahooks';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userInfo as recoilUserInfo, appReady } from '../../recoil';
import { envConfig, nodeEnv } from '../../envConfig';
import { useI18nHook } from '../../i18n';
import { language } from '../../recoil';

import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getToken, getUserInfo } from '../../api';
import './mainLayout.less';

const { Header, Content, Footer, Sider } = Layout;

export function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const ticket = new URLSearchParams(window.location.search).get('sso_ticket');
  const [selectedKey, setSelectedKey] = useState<Array<string>>(['appManagement'])
  const [OpenKey, setOpenKey] = useState<Array<string>>(['developerCenter'])
  const [userInfo, setUserInfo] = useRecoilState(recoilUserInfo);
  const setAppReady = useSetRecoilState(appReady);
  // 预留位置，为国际化做准备
  // const i = (param: string) => param
  const i = useI18nHook()
  const setLanguageType = useSetRecoilState(language)

  // ticket交换token
  const { runAsync: runGetTokenAsync } = useRequest(getToken, {
    manual: true,
    onSuccess: (data) => {
      const token = data.data
      if(token.accessToken){
        localStorage.setItem('accessToken', token.accessToken);
        localStorage.setItem('refreshToken', token.refreshToken);
        setTimeout(()=>{
          runGetUserInfo()
        },1000)
        window.history.pushState({}, "client", "/#/")
        navigate('/developerCenter/appManagement');
        setSelectedKey(['appManagement'])
      }
    },
    onError: (err) => {
      console.log(err);
      // TODO: 错误处理 重定向回login
      message.error(i('token获取失败，请重新登录'));
      window.location.href = `${envConfig[nodeEnv].logout}?app=${encodeURIComponent(document.location.origin + document.location.pathname)}`
    }
  })

  // 获取用户信息
  const { run: runGetUserInfo } = useRequest(getUserInfo, {
    manual: true,
    onSuccess: (data) => {
      setAppReady(true)
      if(data){
        setUserInfo(data)
      }
    },
    onError: (err) => {
      message.error(i('用户信息获取失败，请重新登录'));
      window.location.href = `${envConfig[nodeEnv].logout}?app=${encodeURIComponent(document.location.origin + document.location.pathname)}`
    }
  })

  useAsyncEffect(async () => {
    const token = localStorage.getItem("accessToken");

    const routesArray = location.pathname.split('/');
    if(routesArray[routesArray.length-1]){
      setSelectedKey([routesArray[routesArray.length-1]])
    }else{
      setSelectedKey(['appManagement'])
    }
    setOpenKey([routesArray[routesArray.length-2]])

    // 获取用户信息
    // if(userInfo.person)

    if(ticket){
      // 去换token
      await runGetTokenAsync(ticket)
    }else{
      if(token) {
        if(!userInfo.person){
          runGetUserInfo()
        }
        // 没有token sso登录
        if(location.pathname === '/'){
          navigate('/developerCenter/appManagement');
        }
        
      }else{
        /*
          * 环境：prod
          * app_url： 为当前站点页面全路径
        */
        window.location.href = `${envConfig[nodeEnv].login}?app=${encodeURIComponent(document.location.origin + document.location.pathname)}`
      }
    }
  }, [])

  const handleMenuSelect = ({ keyPath }: any) => {
    console.log(keyPath)
    navigate(`/${keyPath[1]}/${keyPath[0]}`);
    setSelectedKey([keyPath[0]])
  }

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = `${envConfig[nodeEnv].logout}?app=${encodeURIComponent(document.location.origin + document.location.pathname)}`
  }

  const menu = (
    <Menu>
      <Menu.Item key='logout'>
        <Button type='link' onClick={handleLogout}>
          {i("登出")}
        </Button>
      </Menu.Item>
    </Menu>
  );
  const languageList = [
    {locale:"zh-CN",language:"简体中文"},
    {locale:"en-US",language:"English"},
    {locale:"ko",language:"한글"},
    {locale:"pl",language:"Polski"},
  ]
  const changeLanguage = (e:MouseEvent,item:{locale:string,language:string}) => {
    e.preventDefault()
    setLanguageType(item.locale)
  }
  const languageEle = (
    <Menu>
      {languageList.map(item => {
        return (<Menu.Item key={item.locale}>
        <a onClick={(e) => changeLanguage(e,item)}>
          {item.language}
        </a>
      </Menu.Item>)
      })}
    </Menu>
  )

  return (
    <Layout className='main'>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div className="logo">
          <span className='name'>
            {i("高仙")}
            {/* {(userInfo as any).tenant.abbreviation} */}
            {/* {'阿斯顿发阿斯顿发阿斯顿了解阿斯顿发送到发送到发送到发送到发送到发送到发送到发'} */}
          </span>
        </div>
        <Menu theme="dark" mode="inline" 
          selectedKeys={selectedKey[0] ? selectedKey : ['appManagement']} 
          defaultOpenKeys={OpenKey[0] ? OpenKey : ['developerCenter']} 
          onSelect={handleMenuSelect}>
          <Menu.SubMenu key="developerCenter" title={i('开发者中心')}>
            <Menu.Item key="appManagement" title={i('应用管理')}>
              {i('应用管理')}
            </Menu.Item>
            <Menu.Item key="accessKeyManagement" title={i('AccessKey管理')}>
              {i('AccessKey管理')}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
        <Dropdown overlay={menu}>
          <Button type='link' className="userMenu">
            {userInfo.person ? userInfo.person.username : i('未登录')}
          </Button>
        </Dropdown>

        <Dropdown overlay={languageEle} >
          <Button type='link' className="languageMenu">
            {i("语言")}
          </Button>
        </Dropdown>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Copyright ©2019-2020{i("上海高仙自动化科技发展有限公司")}</Footer>

      </Layout>
    </Layout>
  );
}
