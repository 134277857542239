import axios, { AxiosRequestConfig } from "axios";
import { message } from "antd";
import {envConfig, nodeEnv} from '../envConfig';

/**
 * 网络请求发生错误时的处理
 * @param err
 * @param sendData
 * @param options
 */
export async function errorAction(err: AnyObj, sendData: AnyObj, options: RequestOptions): Promise<void> {
  const { status, data } = err.response;

  console.log(data);

  switch(status){
    case 403:
      message.error('当前用户无权限执行此操作！');
      break;
  }
}

/**
 * 刷新token的方法
 * @param method 请求方法
 * @param url 请求地址
 * @param body 请求参数
 * @returns 因过期被拒绝的请求
 */
export async function refreshTokenAndRequestAgain(sendData: AxiosRequestConfig): Promise<any> {
  const refreshToken = localStorage.getItem('reach_refreshToken')
  if(!refreshToken){
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessToken')
    window.location.href = `${envConfig[nodeEnv].logout}?app=${encodeURIComponent(document.location.origin + document.location.pathname)}`
    return
  }
  try {
      const resp: any = await axios.post(envConfig[nodeEnv].refreshToken, { refreshToken: localStorage.getItem('reach_refreshToken') });
      const { data } = resp;
      const { accessToken, refreshToken } = data.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` };

      axios({...sendData, headers})
        .then((res) => {
          const data:any = res.data;
          if (data.code === 200) {
            return Promise.resolve(data);
          } else {
            return Promise.reject(data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
  } catch (error: any) {
    // 登出
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessToken')
    window.location.href = `${envConfig[nodeEnv].logout}?app=${encodeURIComponent(document.location.origin + document.location.pathname)}`;
  }
}
